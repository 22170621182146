<template>
  <ReportTable
    name="cameras"
    item-key="exid"
    :sort-desc="false"
    sort-by="status"
    :headers="headers"
    :provider="getBimCompareReportCameras"
    :copy-to-clipboard="false"
    :hide-show="false"
    :filter-fields="filterFields"
  >
    <template #actions-left>
      <div class="body-1 text--secondary my-3">Bim</div>
    </template>
    <template #item.name="{ item }">
      <nuxt-link
        :to="`/bim-compare/${item.exid}`"
        @click.native="cameraDialogStore.camera = item"
      >
        <span class="cursor-pointer primary--text">{{ item.name }} </span>
      </nuxt-link>
    </template>
    <template #item.status="{ item }">
      <div :class="getStatusColor(item.status)">
        {{ getStatusText(item.status) }}
      </div>
    </template>
    <template #item.fullname="{ item }">
      {{ item.userFullname }}
      <ImpersonateUserButton
        :email="item.userEmail"
        :target-route="`/projects/${item.projectExid}/${item.exid}`"
      />
    </template>
    <template #item.projectName="{ item }">
      <NdaIcon v-if="isNdaProject(item)" />
      {{ item.projectName }}
      <ImpersonateUserButton
        :email="item.userEmail"
        :target-route="`/projects/${item.projectExid}`"
        :nda-project="isNdaProject(item)"
      />
    </template>
    <template #item.demo="{ item }">
      <ImpersonateUserButton
        v-if="isNdaProject(item)"
        :email="item.userEmail"
        :target-route="`/demos/cameras/${item.exid}?page=/bim-compare`"
        :nda-project="isNdaProject(item)"
        icon="fas fa-desktop"
      />
      <OpenInNewWindowButton
        v-else
        :target-link="`/demos/cameras/${item.exid}?page=/bim-compare`"
        hint="Click to open as a demo"
        icon="fas fa-desktop"
      />
    </template>
  </ReportTable>
</template>

<script lang="ts">
import filterFields from "@/components/cameras/camerasSearchFilters"
import CameraUtils from "@/mixins/cameraUtils"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import ReportTable from "@/components/ReportTable"
import NdaIcon from "@/components/NdaIcon"
import headers from "@/components/bim/bimListHeaders"
import { CameraFeatureFlag } from "@evercam/shared/types/camera"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default Vue.extend({
  name: "BimReport",
  components: {
    ReportTable,
    OpenInNewWindowButton,
    ImpersonateUserButton,
    NdaIcon,
  },
  mixins: [CameraUtils],
  data() {
    return {
      filterFields: {},
      headers: headers,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  created() {
    this.filterFields = filterFields(this)
  },
  methods: {
    isNdaProject(item) {
      return item.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
    getBimCompareReportCameras(requestParams = { params: {} }) {
      return AdminApi.cameras.getCameras({
        ...requestParams,
        params: {
          ...requestParams.params,
          featureFlags: [CameraFeatureFlag.BimCompare],
        },
      })
    },
  },
})
</script>
