export default [
  {
    value: "name",
    text: "Name",
    sortable: true,
    filterable: true,
    width: 250,
  },
  {
    value: "exid",
    text: "ID",
    sortable: true,
    filterable: true,
    width: 170,
  },
  {
    value: "status",
    text: "Status",
    sortable: true,
    filterable: true,
    width: 120,
    align: "center",
  },
  {
    value: "demo",
    text: "Demo",
    sortable: false,
    width: 40,
    align: "center",
  },
  {
    value: "fullname",
    text: "Owner",
    sortable: true,
    filterable: true,
  },
  {
    value: "projectName",
    text: "Project",
    sortable: true,
    filterable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    sortable: true,
    filterable: true,
  },
  {
    value: "lastOnlineAt",
    text: "Last Online At",
    sortable: true,
    filterable: true,
  },
]
